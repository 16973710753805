<template>
  <b-modal :active.sync="modalAtivo" has-modal-card>
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title">
          <b>Alterar Senha</b>
        </p>
      </header>
      <section class="modal-card-body">
        <div class="columns">
          <div class="column">
            <b-field label="Senha Atual">
              <b-input type="password" v-model="form.senhaAtual" required />
            </b-field>
            <b-field
              label="Nova Senha"
              message="Para seguir com o reset informe a nova senha."
            >
              <b-input type="password" v-model="form.senhaNova" required />
            </b-field>
          </div>
        </div>
      </section>
      <footer class="modal-card-foot">
        <button class="button" type="button" name="cancelar" @click="cancel">
          Cancelar
        </button>
        <button
          class="button is-primary"
          style="float: right"
          name="confirmar"
          @click="confirm"
        >
          Confirmar
        </button>
      </footer>
    </div>
  </b-modal>
</template>

<script>
import usuarioService from '@/services/usuarioService';

export default {
  name: 'ModalResetarSenha',
  props: {
    ativo: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      form: {},
      modalAtivo: false,
    };
  },
  watch: {
    ativo(novoValor) {
      this.modalAtivo = novoValor;
    },
    modalAtivo(novoValor) {
      if (!novoValor) {
        this.cancel();
      }
    },
  },
  methods: {
    cancel() {
      this.$emit('cancel');
    },
    confirm(event) {
      event.preventDefault();

      usuarioService
        .alterarSenha(this.form)
        .then(() => {
          this.form = {};
          this.notificar('Usuario editado com sucesso');
          this.$emit('confirm');
        })
        .catch(() => {
          this.notificar(
            'Erro ao resetar a senha, tente novamente!',
            'is-success',
          );
        });
    },
    notificar(message, type, position = 'is-bottom-right') {
      this.$buefy.snackbar.open({
        message,
        type,
        position,
        actionText: 'OK',
        queue: false,
        duration: 3000,
      });
    },
  },
};
</script>
