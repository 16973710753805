<template>
  <div class="full-height">
    <header class="main-header">
      <b-navbar class="main-nav-admin">
        <template slot="brand">
          <b-button class="burguer-brand" :style="{ padding: 0 }" @click="toggleSidebar">
            <b-icon icon="menu" />
          </b-button>
          <router-link tag="a" class="navbar-item" :to="{ path: '/admin' }" @click.native="$scrollToTop">
            <img src="@/assets/logo.350.webp" alt="Logo Olimpia Parques" />
          </router-link>
        </template>
        <template slot="end">
          <div class="is-flex" style="align-items: center;">
            <b-dropdown :triggers="['hover']" aria-role="list">
              <p class="navbar-item has-text-weight-bold" slot="trigger">
                {{ nomeUsuario }}
              </p>
              <b-dropdown-item style="padding: 0;" aria-role="listitem">
                <b-button class="is-text" style="text-decoration: none; font-size: 15px" @click="resetarSenha">
                  Alterar Senha
                </b-button>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <a class="navbar-item" @click="handleLogout">
            <b-icon icon="logout" custom-size="default" />
            <span>Logout</span>
          </a>
        </template>
      </b-navbar>
    </header>
    <main class="main-content-admin" style="height: calc(100% - 20px) !important">
      <TheSidebar :position-absolute="false" :sidebar-opened="sidebarOpened" v-click-outside="closeSidebar" />
      <div class="page-content-admin">
        <router-view />
      </div>
    </main>
    <ModalResetarSenha :ativo="modalResetarSenhaAtivo" @confirm="salvarSenha" @cancel="fecharModalResetarSenha" />
  </div>
</template>

<script>
import TheSidebar from '@/components/TheSidebar';
import ModalResetarSenha from '@/components/ModalResetarSenha.vue';
import { mapMutations, mapState } from 'vuex';

export default {
  name: 'AdminTemplate',
  components: { TheSidebar, ModalResetarSenha },
  data() {
    return {
      sidebarOpened: true,
      modalResetarSenhaAtivo: false,
    };
  },
  computed: {
    ...mapState(['usuario']),
    nomeUsuario() {
      return this.usuario?.nome;
    },
  },
  methods: {
    ...mapMutations(['logout']),
    toggleSidebar(event) {
      event.stopPropagation();
      this.sidebarOpened = !this.sidebarOpened;
    },
    closeSidebar() {
      this.sidebarOpened = false;
    },
    handleLogout() {
      this.logout();

      this.$router.push({ name: 'login' });

      this.$buefy.toast.open({
        message: 'Sessão encerrada',
        type: 'is-info',
        queue: false,
      });
    },
    resetarSenha() {
      this.modalResetarSenhaAtivo = true;
    },
    salvarSenha() {
      this.modalResetarSenhaAtivo = false;
      this.handleLogout();
    },
    fecharModalResetarSenha() {
      this.modalResetarSenhaAtivo = false;
    },
  },
};
</script>
