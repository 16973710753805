<template>
  <div
    class="default-sidebar"
    :class="{
      'position-absolute': positionAbsolute,
      'default-sidebar-opened': sidebarOpened,
    }"
  >
    <div class="navigation-section">
      <b-menu>
        <b-menu-list label="Menu">
          <b-menu-item v-for="(menu, index) in sidebarRoutes" :key="index" :icon="menu.icon">
            <template #label="props">
              {{ menu.name }}
              <b-icon class="is-pulled-right" :icon="props.expanded ? 'menu-up' : 'menu-down'"></b-icon>
            </template>
            <b-menu-item v-for="(route, index) in menu.routes" :key="index" :to="route.path" tag="router-link">
              <template #label>
                {{ route.name }}
              </template>
            </b-menu-item>
          </b-menu-item>
        </b-menu-list>
      </b-menu>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  name: 'TheSidebar',
  props: {
    sidebarOpened: {
      type: Boolean,
      required: true,
    },
    positionAbsolute: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(['sidebarRoutes']),
    currentRoutePath() {
      return this.$route.path;
    },
  },
  methods: {
    isCurrentRouteEqualToAnyChildRoutePath(routes = []) {
      return routes.some(s => s.path === this.currentRoutePath);
    },
    menuIsActive(menu) {
      if (!menu) {
        return false;
      }

      return this.isCurrentRouteEqualToAnyChildRoutePath(menu?.routes);
    },
  },
};
</script>

<style lang="scss">
.menu {
  .menu-list {
    a {
      padding: 0.75em 0.75em !important;
    }
  }
}
</style>
